
import React from 'react';

export function TzList ( props )
{
	const displaySettings = props.displaySettings;
	const currentTimeMs = props.currentTimeMs;

	const rows = displaySettings.tzList.map ( ( entry, index ) => {

		var timeRendering = new Date ( currentTimeMs ).toLocaleString("en-US", { timeZone: entry.label });
		var offsetHours = entry.offset / 60;

		return <tr key={entry.label + "_" + entry.index}>
			<td>{entry.label}</td>
			<td>{offsetHours != 0 ? offsetHours : "" }</td>
			<td>{timeRendering}</td>
		</tr>
	} );

	return (
		<table>
			<thead>
				<tr><th>Time Zone</th><th>Offset</th><th>Time</th></tr>
			</thead>
			<tbody>
				{rows}
			</tbody>
		</table>
	);
}
