
import React from 'react';

export function Header ( props )
{
	return (
        <div className="header">
<pre>
&nbsp;&nbsp;&nbsp;___  ____   ___     __  __ __      ______   ___    ___   _     <br/>
&nbsp;&nbsp;/  _]|    \ /   \   /  ]|  |  |    |      | /   \  /   \ | |    <br/>
&nbsp;/  [_ |  o  )     | /  / |  |  |    |      ||     ||     || |    <br/>
|    _]|   _/|  O  |/  /  |  _  |    |_|  |_||  O  ||  O  || |___ <br/>
|   [_ |  |  |     /   \_ |  |  |      |  |  |     ||     ||     |<br/>
|     ||  |  |     \     ||  |  |      |  |  |     ||     ||     |<br/>
|_____||__|   \___/ \____||__|__|      |__|   \___/  \___/ |_____|<br/>
</pre>
        </div>
	);
}
