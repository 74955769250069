
import React from 'react';

export function Trivia ( props )
{
    if ( !props.displaySettings.trivia )
    {
        return <></>;
    }

	return (
            <div>
                (trivia)
            </div>
	);
}
