
import React from 'react';
import { render } from 'react-dom';
import { App } from './App';

import log from 'loglevel';

log.setLevel ( "info" );

render (
	<App/>,
	document.getElementById('root')
);
