
import React from 'react';

export function Now ( props )
{
	var currentTimeMs = props.currentTimeMs;
	var displaySettings = props.displaySettings;

	var displayTime = Math.floor ( currentTimeMs / displaySettings.resolution );

	function copyToClipboard ()
	{
		/* Create a temporary input element */
		var tempInput = document.createElement("input");
		tempInput.setAttribute("value", currentTimeMs);
	
		/* Append the temporary input element to the document */
		document.body.appendChild(tempInput);
	
		/* Select the text in the temporary input element */
		tempInput.select();
		tempInput.setSelectionRange(0, 99999); /* For mobile devices */
	
		/* Copy the selected text to the clipboard */
		document.execCommand("copy");
	
		/* Remove the temporary input element from the document */
		document.body.removeChild(tempInput);
	
		/* Provide some visual feedback */
		alert("Text copied to clipboard: " + currentTimeMs);
	}
	
	return (
        <div className="nowHolder">
		<div>
			<span className="now"><button className="nowCopyButton" onClick={copyToClipboard}>{displayTime}</button></span>
        </div>
		<div className="miniHelper">click to copy</div>
		</div>
	);
}
