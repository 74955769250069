
import React, { useState, useEffect } from 'react';

import log from 'loglevel';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Now } from './panes/now';
import { Trivia } from './panes/trivia';
import { Header } from './panes/header';
import { TzList } from './panes/tzList';

log.setLevel ( "info" );

const updateEveryMs = 1000;

const kResolution_Ms = 1;
const kResolution_MicroSec = kResolution_Ms/1000;
const kResolution_Sec = kResolution_Ms * 1000;

// this user's time zone info
const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const offsetMinutes = new Date().getTimezoneOffset();   // ADD this to get to UTC

const kDefaultDisplaySettings =
{
    resolution: kResolution_Ms,
    tz: currentTimeZone,
    tzOffsetMinutes: offsetMinutes,
    tzList: [ { "label": "UTC", "offset": 0 }, { "label": currentTimeZone, "offset": offsetMinutes } ],
    trivia: false
};

export function App ( props )
{
    var [ currentTimeMs, setCurrentTimeMs ] = useState ( new Date().getTime() );
    var [ displaySettings, setDisplaySettings ] = useState ( kDefaultDisplaySettings );

    // update our current time every 'updateEveryMs' ms
    useEffect(() => {
        const interval = setInterval(() =>
        {
            setCurrentTimeMs ( new Date().getTime() );
        }, updateEveryMs );

        // Cleanup the interval when the component unmounts or when the dependency changes
        return () => clearInterval ( interval );
    }, [] ); // Empty dependency array ensures the effect runs only once

    return (
        <div className="outerframe">
            <Header displaySettings={displaySettings} />
            <Now currentTimeMs={currentTimeMs} displaySettings={displaySettings} />
            <TzList currentTimeMs={currentTimeMs} displaySettings={displaySettings}/>
            <Trivia displaySettings={displaySettings}/>
        </div>
	);
}
